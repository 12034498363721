import VueRouter from 'vue-router'
import { pathNeedLogin } from '@/utils/default'
import store from '@/store/index'

import Home from '@/views/Home'
import About from '@/views/About'
import Demo from '@/views/Demo'
import Code from '@/views/Code'
import NotFound from '@/views/NotFound'
import User from '@/views/User'
import Login from '@/views/User/Login'
import Register from '@/views/User/Register'

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/demo', component: Demo, name: 'demo' },
  { path: '/code', component: Code, name: 'code' },
  { path: '/user', component: User, name: 'user' },
  { path: '/login', component: Login, name: 'login' },
  { path: '/register', component: Register, name: 'register' },
  { path: '/about', components: { default: About }, name: 'about' }, // 写法效果同 { component: About }，注意后缀的s，参考 https://v3.router.vuejs.org/zh/guide/essentials/named-views.html
  { path: '*', component: NotFound, name: 'nf404' },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from ,next) => {
  // console.log('我是全局路由守卫beforeEach, to:', to, 'from:', from);

  pathNeedLogin(to.path) && !store.state.user.name ? next({ name: 'login', query: { redirect: to.fullPath } }) : next();
})

export { router }
