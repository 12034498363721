import Vue from 'vue'
import App from './App.vue'

import { 
  Button, Layout, Menu, FormModel, Input, Row, Col,
  Message, Avatar, Modal
  } from 'ant-design-vue';

import VueRouter from 'vue-router';
import { router } from '@/router'

import store from '@/store/index'

import http from '@/api/request'
import '@/mock/index'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Avatar)
Vue.use(Modal)

Vue.prototype.$http = http;
Vue.prototype.$message = Message;
Vue.prototype.Modal = Modal;

window.store = store;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
