<template>
  <div>
    Home Page
  </div>
</template>

<script>
// import { user } from "@/api/user"

export default {
  data() {
    return {}
  },
  mounted() {
    // user()
    // console.log('this.$store', this.$store);
    // console.log(this.$store.state);
    // this.$store.commit('increment')
    // console.log(this.$store.state);

  }
}
</script>

<style lang="less" scoped></style>
