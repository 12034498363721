<template>
  <div class="main-container">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
.main-container {
  background-color: #fff;
  height: 100%;
  padding: 24px;
}
</style>