<template>
  <a-layout class="layout-container">
    <a-layout-header class="header">
      <Header></Header>
    </a-layout-header>
    <a-layout-content class="main">
      <Main></Main>
    </a-layout-content>
    <a-layout-footer class="footer">
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Header from '@/layout/hearder'
import Main from '@/layout/main'
import Footer from '@/layout/footer'

export default {
  data() {
    return {}
  },
  components: {
    Header, 
    Main, 
    Footer
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  min-height: 100vh;

  .header {
    background-color: #fff;
  }

  .main {
    height: 1400px;
    padding: 24px 50px;
  }

  .footer {
    background-color: #aaa;
  }
}
</style>