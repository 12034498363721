import myAxios from './axios'

const req = {
  /**
   * get请求
   * @param {string} url - 请求地址
   * @param {object} params - 地址后面的query参数
   * @param {object} config - get请求的其他配置，可覆盖前两个参数
   * @returns 
   */
  get(url, params={}, config={}) {
    return new Promise((resolve, reject) => {
      myAxios({
        url,
        method: 'get',
        params,
        ...config
      }).then((data)=>{ resolve(data) }).catch((err)=>{ reject(err) })
    })
  },
  post(url, data={}, config={}) {
    return new Promise((resolve, reject) => {
      myAxios({
        url,
        method: 'post',
        data,
        ...config
      }).then((data)=>{ resolve(data) }).catch((err)=>{ reject(err) })
    })
  },
  put(url, data={}, config={}) {
    return new Promise((resolve, reject) => {
      myAxios({
        url,
        method: 'put',
        data,
        ...config
      }).then((data)=>{ resolve(data) }).catch((err)=>{ reject(err) })
    })
  },
  delete(url, data={}, config={}) {
    return new Promise((resolve, reject) => {
      myAxios({
        url,
        method: 'delete',
        data,
        ...config
      }).then((data)=>{ resolve(data) }).catch((err)=>{ reject(err) })
    })
  },
}

export default req;