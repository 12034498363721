export default {
  "OK": (data, msg) => ({
    code: 200,
    message: msg || "请求成功",
    data
  }),
  "Created": (data, msg) => ({
    code: 201,
    message: msg || "请求成功",
    data
  }),
  "Unauthorized": msg =>({
    code: 401,
    message: msg || "未认证，请登录"
  }),
  "Forbidden": msg =>({
    code: 403,
    message: msg || "无权限，用户未被授予访问权限",
  }),
  "NotFound": msg => ({
    code: 404,
    message: msg || "未找到资源"
  }),
  "ServiceError": msg => ({
    code: 500,
    message: msg || "服务器相关报错。（模拟）",
  })
}
