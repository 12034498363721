<template>
  <div class="login-container">
    Login Page

    <a-row justify="center" type="flex">
      <a-col :span="10">
        <a-form-model :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" ref="loginForm" :rules="rules">
          <a-form-model-item label="用户名" prop="name">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password">
            <a-input v-model="form.password" type="password" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
            <a-button type="primary" @click="onSubmit">
              登录
            </a-button>
            <a-button style="margin-left: 10px;" @click="resetForm">
              Reset
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>


  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        password: '',

      },
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },]
      }
    }
  },
  methods: {
    onSubmit() {
      // console.log('onSubmit');
      // console.log(this.$route.query);
      // 先校验
      this.$refs.loginForm.validate(valid=>{
        if(valid) {
          // console.log(this.form);
          this.$store.dispatch('login', { ...this.form, ...this.$route.query })
          // alert('submit')
        } else {
          console.log('error');
          return false;
        }
      })
    },
    resetForm() {
      this.$refs.loginForm.resetFields();
    }
  }
}
</script>

<style lang="less" scoped></style>