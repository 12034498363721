import Mock from 'mockjs'
import ResFunc from './response'
import { userPath } from '@/api/user'

Mock.mock(userPath.user, 'get', (query)=>{
  console.log(`mock: ${userPath.user}, query: ${query}`);
})

Mock.mock(userPath.register, 'post', (param)=>{
  console.log(`mock: ${userPath.register}, param: `, param);
})

Mock.mock(userPath.login, 'post', (param)=>{
  console.log(`mock: ${userPath.login}, param: `, param);
  const payload = JSON.parse(param.body);
  if (payload.name === "admin" && payload.password === '123456') {
    // 模拟登录成功
    return ResFunc["OK"]({name: "张三", sex: "男", avatar: Mock.Random.dataImage('32x32') }, "登录成功")
  } else {
    return ResFunc["Unauthorized"]("账号密码错误。")
  }
})
