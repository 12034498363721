<template>
  <div>
    User Page
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>

</style>