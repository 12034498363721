import http from './request'

const basePath = "/user"

let userPath = {
  "user": "",
  "register": "/register",
  "login": '/login'
}

Object.keys(userPath).forEach(key => {
  userPath[key] = basePath + userPath[key]
});

export { userPath }

export function user(query) {
  return http.get(userPath.user, query);
}

export function register(payload) {
  return http.post(userPath.register, payload);
}

export function login(payload) {
  return http.post(userPath.login, payload);
}
