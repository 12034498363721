import { needNotLoginPath } from '@/config/inedx'

export function setHeightTransitional(targetEl, TransitionalEl) {
  targetEl.addEventListener('mouseenter', function(){
    TransitionalEl.style.height = 'auto';
    let { height } = TransitionalEl.getBoundingClientRect();
    TransitionalEl.style.height = '0';
    TransitionalEl.style.transition = '0.5s';
    TransitionalEl.getBoundingClientRect(); // 强制回流
    TransitionalEl.style.height = height + 'px';
  });
  targetEl.addEventListener('mouseleave', function(){
    TransitionalEl.style.height = '0px';
  })
}

/**
 * 判断访问传进来的path是否需要登录
 * @param {*} fullPath 
 * @returns 
 */
export function pathNeedLogin(path) {
  let needLogin = true;
  for(const p of needNotLoginPath) {
    // console.log(path);
    if (p === path) {
      needLogin = false;
    }
  }
  return needLogin;
}