<template>
  <div class="user-container">
    <template v-if="user.name">
      <div class="login">
        <div class="avatar"><a-avatar :size="32" :src="user.avatar" /></div>
        <!-- <div class="user-name">{{ user.name }}</div> -->
      </div>
      <div class="login-desc">
        <div class="avatar"><a-avatar :size="32" :src="user.avatar" /></div>
        <div class="name">{{ user.name }}</div>
        <div class="sex">{{ user.sex }}</div>
        <div class="logout" @click="logout">注销</div>
      </div>
    </template>
    <router-link v-else :to="{ name: 'login' }" class="login">请登录</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    logout() {
      const _self = this;
      this.Modal.confirm({
        title: "确定要注销登录吗？",
        keyboard: false,
        async onOk() {
          await _self.$store.dispatch('logout')
        },
        onCancel() {
          // _self.Modal.destroyAll()
        }
      })
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
}
</script>

<style lang="less" scoped>
.user-container {
  width: 100%;
  .login {
    display: flex;
    justify-content: center;
    gap: 10px;
    &:hover + .login-desc {
      height: auto;
    }
  }
  .login-desc {
    z-index: 9;
    position: relative;
    // border: 1px solid red;
    // height: 128px;
    width: 256px;
    transform: translateX(-50%);
    background-color: #eee;
    // border: 1px solid #ccc;
    border-radius: 5px;
    height: 0;
    overflow: hidden;
    &:hover {
      height: auto;
    }

    .logout {
      cursor: pointer;
      &:hover {
        background-color: #ccc;
      }
    }
  }
}
</style>